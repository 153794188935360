@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 900px) {
  .pcnone {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 500;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 500;
  color: #333333;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 2rem;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.inner2 {
  width: calc(100% - 12%);
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 80px;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 0;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav.panelactive {
  background-color: #fff;
}

.navR {
  display: flex;
  align-items: center;
  padding: 20px;
}

@media only screen and (min-width: 835px) and (max-width: 1463px) {
  .navR {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

#g-nav {
  position: relative;
}

@media only screen and (max-width: 900px) {
  #g-nav {
    display: block;
  }
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 50px;
  }
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li a {
  text-align: center;
  padding: 10px 15px;
  display: block;
  position: relative;
  color: #333333;
  font-weight: 500;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  #g-nav .mainNav li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    text-align: left;
    position: relative;
    font-weight: 500;
    border-left: none;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

#toggle .is-open {
  display: block;
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (max-width: 900px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.dropdown li:last-of-type a {
  border-bottom: none;
}

.dropdown li a {
  background-color: #3654b2;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #fff;
  padding: 15px !important;
}

@media only screen and (max-width: 900px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #333333 !important;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: #3654b2;
  border-radius: 0 0 0 50px;
  box-shadow: -29px 28px 41px -49px #dcdada;
  z-index: 10000;
  padding-bottom: 20px;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #fff;
  width: 35%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header .h-logo {
  padding: 20px;
}

.header {
  z-index: 9999;
  position: fixed;
  top: 0;
  height: fit-content;
  left: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.header--unpinned {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

.header--pinned {
  background-color: #fff;
}

@media only screen and (max-width: 900px) {
  .header--pinned {
    background-color: inherit;
  }
}

.header--pinned #g-nav {
  display: block;
}

.fix {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  .fix {
    bottom: 0;
    top: unset;
    left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    transform: none;
  }
}

.fix a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  border-radius: 10px 0 0 10px;
  padding: 20px;
  font-size: 14px;
  transform: translateX(205px);
}

@media only screen and (max-width: 900px) {
  .fix a {
    border-radius: 10px 10px 0 0;
    transform: none;
  }
}

.fix a i {
  display: block;
  font-size: 2rem;
}

.fix a:hover {
  transform: none;
  transition: all 0.3s ease;
}

.fix a .btn_R {
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 20px;
}

@media only screen and (max-width: 900px) {
  .fix a .btn_R {
    display: none;
  }
}

.fix .h_tel {
  background-color: #3654b2;
}

.fix .h_tel .btn_L {
  padding-left: 5px;
}

@media only screen and (max-width: 900px) {
  .fix .h_tel .btn_L {
    padding-left: 0;
  }
}

.fix .h_tel .btn_R .time {
  display: block;
  font-size: 16px;
}

@media only screen and (max-width: 900px) {
  .fix .h_tel .btn_R .time {
    display: none;
  }
}

.fix .h_mail {
  background-color: #13a9b7;
}

.fix .h_line {
  background-color: #06C152;
}

.h_contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .h_contact {
    display: none;
  }
}

.h_contact a {
  font-weight: 500;
  color: #fff;
  padding: 15px;
  min-width: 250px;
  border-radius: 10px;
  display: block;
}

.h_contact .h_tel {
  background-color: #3654b2;
  margin-right: 15px;
}

.h_contact .h_mail {
  background-color: #13a9b7;
}

.h_contact i {
  margin-right: 8px;
}

.mainWrap {
  position: relative;
}

.mainWrap .mainText {
  position: absolute;
  left: 5%;
  bottom: 15%;
  font-size: clamp(30px, 5vw, 56px);
  text-align: left;
  line-height: 1.25em;
  font-weight: 900;
  text-shadow: 0 0 10px rgba(51, 51, 51, 0.3);
}

.mainArea img {
  width: 100% !important;
}

.subArea {
  padding: 10rem 1rem;
  background-position-y: center;
}

@media screen and (max-width: 834px) {
  .subArea {
    padding: 5rem 24px;
  }
}

.subArea h1 {
  font-size: clamp(36px, 5vw, 52px);
  line-height: 1.25em;
  font-weight: 900;
  color: #fff;
}

.blog_main {
  background: url(../images/contents/local_main.jpg);
  font-size: clamp(36px, 5vw, 52px);
  line-height: 1.25em;
  font-weight: 900;
  color: #fff;
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #333333;
}

@media screen and (max-width: 640px) {
  .pankuzu {
    margin-top: 0;
  }
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  background-color: #3654b2;
  padding: 60px 0;
  color: #fff;
}

@media only screen and (max-width: 900px) {
  footer#global_footer {
    text-align: center;
  }
}

footer#global_footer .ftNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
  footer#global_footer .ftNav {
    justify-content: flex-start;
  }
}

footer#global_footer .ftNav li {
  text-align: left;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav li {
    width: 100%;
  }
}

footer#global_footer .ftNav li a {
  text-align: left;
  padding: 20px;
  display: block;
  color: #fff;
  line-height: 1em;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav li a {
    text-align: center;
  }
}

footer#global_footer .copy {
  margin-top: auto;
}

@media only screen and (max-width: 900px) {
  footer#global_footer .copy {
    margin-top: 30px;
  }
}

footer#global_footer #copy {
  color: #fff !important;
  font-size: 14px;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
}

.title_01 h2 {
  font-size: clamp(30px, 3vw, 40px);
  line-height: 1.5em;
  font-weight: bold;
}

.title_01 h2 .deco {
  color: #3654b2;
  position: relative;
}

.title_01 h2 .deco::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #3654b2;
}

.title_02 h2 {
  font-size: clamp(30px, 3vw, 40px);
  line-height: 1.5em;
  font-weight: bold;
  color: #fff;
}

.title_02 h2 .deco {
  position: relative;
}

.title_02 h2 .deco::before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  background-color: #fff;
}

.news {
  max-height: 200px;
  overflow-y: auto;
}

.news dt,
.news dd {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .news dt,
  .news dd {
    width: 100% !important;
  }
}

.news dt {
  color: #3654b2;
  font-weight: 500;
}

.news dl {
  border-bottom: 1px solid #f7f7f7;
  padding: 15px 0;
}

@media screen and (max-width: 640px) {
  .news dl {
    display: block !important;
  }
}

.text div {
  line-height: 2.5em;
}

.text h3 {
  font-weight: bold;
  border-bottom: 1px dotted #ccc;
  padding-bottom: 5px;
}

.blog_title_list {
  max-width: 680px;
  max-height: 250px;
  overflow-y: auto;
  margin: auto;
}

.blog_title_list .blog_list {
  border: none !important;
}

.blog_title_list .blog_list > div {
  border: none !important;
  display: block !important;
}

.blog_title_list .blog_list .blog_photo {
  display: none;
}

.blog_title_list .blog_list .blog_text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 1.8rem !important;
  border-bottom: 1px solid #ccc;
  padding: 25px 0;
}

@media screen and (max-width: 640px) {
  .blog_title_list .blog_list .blog_text {
    display: block;
  }
}

.blog_title_list .blog_list .blog_text .blog_date {
  color: #3654b2 !important;
  font-weight: bold;
}

.blog_title_list .blog_list .blog_text h3 {
  margin-bottom: 0 !important;
  font-size: 2rem !important;
}

.blog_title_list .blog_list .blog_text h3 a {
  color: #333333;
}

.blog_title_list .blog_list .detail {
  display: none;
}

.blog_topics_list .blog_list {
  border: none !important;
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
  flex-wrap: wrap;
}

.blog_topics_list .blog_list > div {
  border: none !important;
  display: block !important;
  width: calc((100% - 90px) / 4);
}

@media screen and (max-width: 640px) {
  .blog_topics_list .blog_list > div {
    width: 100%;
    margin-bottom: 30px;
  }
}

.blog_topics_list .blog_list .blog_photo {
  width: 100% !important;
  padding: 0 !important;
}

.blog_topics_list .blog_list .blog_text {
  width: 100%;
  font-size: 1.8rem !important;
  padding-top: 15px;
}

.blog_topics_list .blog_list .blog_text .blog_date {
  color: #3654b2 !important;
  font-weight: bold;
}

.blog_topics_list .blog_list .blog_text h3 {
  margin-bottom: 0 !important;
  font-size: 2rem !important;
}

.blog_topics_list .blog_list .blog_text h3 a {
  color: #333333;
}

.blog_topics_list .blog_list .blog_text .detail {
  margin-top: 15px !important;
}

.bnr a {
  background: url(../images/home/qa_bg.jpg) center;
  background-size: cover;
  display: block;
  font-size: 2.6rem;
  color: #fff;
  padding: 80px;
  border-radius: 10px;
}

.bnr a .in {
  width: fit-content;
  margin-right: auto;
}

.bnr a .deco {
  font-size: 2.2rem;
  display: block;
  margin-bottom: 10px;
}

.cont_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media screen and (max-width: 834px) {
  .cont_01 {
    display: block;
  }
}

.cont_01 .flxL {
  width: 50%;
}

.cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_01 .flxR {
  width: 50%;
}

@media screen and (max-width: 834px) {
  .cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_01 .flxL img {
  border-radius: 10px;
}

.cont_02 {
  background-color: #3654b2;
  padding: 60px 0;
}

.cont_03 {
  background-color: #e7ecfd;
  padding: 60px 0;
}

.cont_04 {
  background: url(../images/home/contact_bg.jpg);
  background-size: cover;
  padding: 60px 0;
  background-attachment: fixed;
}

@media screen and (max-width: 834px) {
  .cont_04 {
    background-attachment: scroll;
  }
}

.cont_05 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

@media screen and (max-width: 834px) {
  .cont_05 {
    display: block;
  }
}

.cont_05 .flxL {
  width: 48%;
}

.cont_05 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_05 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_05 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_05 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_05 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_05 .flxL img {
  border-radius: 10px;
}

.cont_05 .flex_03 .h_tel {
  margin-right: 0;
  margin-bottom: 15px;
}

.flex_01 {
  justify-content: space-between;
}

.flex_01 .box {
  width: calc((100% - 60px) / 3);
  background-color: #fff;
  border: 1px dotted #ccc;
  padding: 60px 30px 30px;
  border-radius: 5px;
  text-align: center;
  position: relative;
  overflow: inherit !important;
}

@media screen and (max-width: 640px) {
  .flex_01 .box {
    width: 100%;
    margin-bottom: 60px;
  }
}

.flex_01 .box article {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex_01 .box article h3 {
  order: 1;
  font-size: 2.2rem;
  font-weight: 900;
  color: #3654b2;
}

.flex_01 .box article img {
  order: 2;
  margin: 20px 0;
}

.flex_01 .box article > div {
  order: 3;
}

.flex_01 .box article > div {
  line-height: 2em;
}

.flex_01 .box .deco {
  background-color: #3654b2;
  border-radius: 100px;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  display: block;
  color: #fff;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  line-height: 1em;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex_01 .box .deco .nb {
  font-size: 3.4rem;
  font-weight: 900;
  display: block;
  line-height: 1em;
}

.flex_02 {
  justify-content: space-between;
}

.flex_02 .box {
  width: calc((100% - 58px) / 2);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 3px 7px 6px rgba(51, 51, 51, 0.3);
  text-align: center;
}

@media screen and (max-width: 640px) {
  .flex_02 .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex_02 .box a {
  padding: 30px;
  display: block;
  position: relative;
}

.flex_02 .box a article > div {
  color: #3654b2;
  font-weight: bold;
  font-size: 2.2rem;
  margin-top: 20px;
}

.flex_02 .box a article > div .deco {
  position: absolute;
  left: 10px;
  top: 10px;
}

.flex_03 {
  justify-content: center;
}

.flex_03 a {
  text-align: center;
  font-weight: 500;
  color: #fff;
  padding: 20px;
  min-width: 350px;
  border-radius: 10px;
  display: block;
  font-size: 2.6rem;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_03 a {
    min-width: 250px;
  }
}

@media screen and (max-width: 640px) {
  .flex_03 a {
    width: 100%;
  }
}

.flex_03 .h_tel {
  background-color: #3654b2;
  margin-right: 30px;
}

@media screen and (max-width: 640px) {
  .flex_03 .h_tel {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.flex_03 .h_mail {
  background-color: #13a9b7;
}

.flex_03 i {
  margin-right: 8px;
}

.gmap iframe {
  width: 100%;
  border-radius: 10px;
}

.top-sns div.sns_list {
  flex-direction: row;
  border: none;
  flex-wrap: wrap;
}

.top-sns div.sns_list > div {
  width: calc((100% - 96px)/4);
  flex-direction: column;
  border: none;
  margin-right: 32px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .top-sns div.sns_list > div {
    width: calc((100% - 48px)/4);
    margin: 0 16px 16px 0;
  }
}

@media screen and (max-width: 640px) {
  .top-sns div.sns_list > div {
    width: calc(50% - 8px);
  }
}

.top-sns div.sns_list > div:nth-child(4n) {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .top-sns div.sns_list > div:nth-child(2n) {
    margin-right: 0;
  }
}

.top-sns div.sns_list > div div.sns_photo {
  width: 100%;
  padding: 0;
}

.top-sns div.sns_list > div div.sns_photo a {
  display: block;
  padding: 0 0 100%;
  position: relative;
  overflow: hidden;
}

.top-sns div.sns_list > div div.sns_photo a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

.top-sns div.sns_list > div div.sns_text {
  display: none;
}

.btn a {
  color: #fff;
  position: relative;
  display: block;
  padding: 20px;
  width: 350px;
  background-color: #3654b2;
  border-radius: 10px;
  margin: auto;
}

.btn a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  position: absolute;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.btn02 a {
  color: #fff;
  position: relative;
  display: block;
  padding: 30px;
  min-width: 350px;
  background-color: #13a9b7;
  border-radius: 10px;
  margin: auto;
  font-size: 1.5em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 834px) {
  .btn02 a {
    font-size: 16px;
  }
}

.btn02 a:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.4);
  opacity: 1 !important;
}

.btn02 a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  position: absolute;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl,
button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl,
  button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title,
.wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2,
.item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a,
.item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover,
.item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.shop .items {
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  .shop .items {
    display: block !important;
  }
}

.shop .items article {
  width: 22.333% !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .shop .items article {
    width: 48% !important;
  }
}

@media screen and (max-width: 640px) {
  .shop .items article {
    width: 100% !important;
  }
}

.shop .items article .photo {
  width: 100% !important;
}

.shop .items article .name {
  color: #e7ecfd !important;
  font-weight: bold;
}

.shop .items article .price {
  color: #333333 !important;
}

.shopPage div.items > article .thumb {
  z-index: 2;
  position: relative;
}

.shopPage div.items > article .hoverImg {
  position: absolute;
  top: 0;
  left: 0;
}

.shopPage div.items > article a.photo {
  position: relative;
}

.shopPage div.items > article a {
  text-decoration: none;
}

.shopPage div.items > article a.photo {
  width: 100%;
  height: auto;
  background-image: none !important;
}

.shopPage div.items > article a.photo img {
  display: block;
  width: 100% !important;
  height: 264px !important;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

#main #col_side1 {
  display: none;
}

.shopPage #main #col_side1 {
  display: block;
  padding-right: 24px;
}

@media screen and (max-width: 834px) {
  .shopPage #main #col_side1 {
    width: 100% !important;
  }
}

.shopPage #col_main {
  width: calc(100% - 300px) !important;
}

@media screen and (max-width: 834px) {
  .shopPage #col_main {
    width: 100% !important;
  }
}

.view_cart a {
  background-color: #3654b2;
  display: block;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.view_cart a i {
  margin-right: 10px;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: clamp(30px, 4vw, 40px);
  position: relative;
  line-height: 1.2em;
  padding: 25px 10px 25px 4rem;
  font-weight: 900;
  border: 1px dotted #3654b2;
  border-radius: 5px;
  background-color: #fff;
}

.local_title_01 h2::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 40px;
  background-color: #3654b2;
  border-radius: 2px;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sub_title_01 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  padding: 15px 15px 15px 7rem;
  font-weight: 900;
  border-bottom: 1px dotted #3654b2;
}

.sub_title_01 h3::before {
  content: url(../images/service/service_icon_01.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_02 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  padding: 15px 15px 15px 7rem;
  font-weight: 900;
  border-bottom: 1px dotted #3654b2;
}

.sub_title_02 h3::before {
  content: url(../images/service/service_icon_02.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_03 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  padding: 15px 15px 15px 7rem;
  font-weight: 900;
  border-bottom: 1px dotted #3654b2;
}

.sub_title_03 h3::before {
  content: url(../images/service/service_icon_03.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_04 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  padding: 15px 15px 15px 7rem;
  font-weight: 900;
  border-bottom: 1px dotted #3654b2;
}

.sub_title_04 h3::before {
  content: url(../images/service/service_icon_04.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_05 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  padding: 15px 15px 15px 7rem;
  font-weight: 900;
  border-bottom: 1px dotted #3654b2;
}

.sub_title_05 h3::before {
  content: url(../images/service/service_icon_05.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_06 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  padding: 15px 15px 15px 7rem;
  font-weight: 900;
  border-bottom: 1px dotted #3654b2;
}

.sub_title_06 h3::before {
  content: url(../images/service/service_icon_06.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_07 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  padding: 15px 15px 15px 7rem;
  font-weight: 900;
  border-bottom: 1px dotted #3654b2;
}

.sub_title_07 h3::before {
  content: url(../images/service/service_icon_07.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_08 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  font-weight: 900;
  position: relative;
  padding: 1rem 2rem;
  border-bottom: 6px solid #3654b2;
}

.sub_title_08 h3::before {
  position: absolute;
  bottom: -6px;
  left: 0;
  width: 20%;
  height: 6px;
  content: '';
  background: #e7ecfd;
}

.sub_title_09 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  font-weight: bold;
  position: relative;
  padding: 1rem 1rem 1rem 16rem;
  border-bottom: 6px solid #3654b2;
}

.sub_title_09 h3::before {
  content: url(../images/succession/step01.png);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_10 h3 {
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: 900;
  position: relative;
  padding-left: 3rem;
  color: #3654b2;
}

.sub_title_10 h3::before {
  content: "●";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_11 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  font-weight: bold;
  position: relative;
  padding: 1rem 1rem 1rem 16rem;
  border-bottom: 6px solid #3654b2;
}

.sub_title_11 h3::before {
  content: url(../images/succession/step02.png);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_12 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  font-weight: bold;
  position: relative;
  padding: 1rem 1rem 1rem 16rem;
  border-bottom: 6px solid #3654b2;
}

.sub_title_12 h3::before {
  content: url(../images/succession/step03.png);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sub_title_13 h3 {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 2.25em;
  color: #3654b2;
  line-height: 1.5em;
}

.sub_title_13 h3 .deco {
  margin-right: 10px;
  color: #13a9b7;
  font-size: 32px;
}

@media screen and (max-width: 640px) {
  .sub_title_13 h3 .deco {
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL {
    max-width: 100% !important;
  }
}

.line {
  position: relative;
  background-color: #fff;
  border-bottom: solid 2px #e7ecfd;
  padding: 16px;
  text-align: center;
}

.line::before, .line::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.line::before {
  border: solid 69px transparent;
  border-top: solid 49px #e7ecfd;
}

.line::after {
  border: solid 73px transparent;
  border-top: solid 51px #fff;
  margin-top: -5px;
  visibility: unset !important;
}

.text_cont {
  background-color: #e7ecfd;
  padding: 30px;
}

.text_cont h3 {
  font-weight: bold;
}

.cont_consulting_01 {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  justify-content: space-around;
}

@media screen and (max-width: 834px) {
  .cont_consulting_01 {
    display: block;
  }
}

.cont_consulting_01 .flxL {
  width: 38%;
}

.cont_consulting_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_consulting_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_consulting_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_consulting_01 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_consulting_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_consulting_01 .img img {
  border-radius: 10px;
}

.cont_consulting_02 {
  position: relative;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .cont_consulting_02 .img {
    padding: 15px !important;
  }
}

.dl_consulting_01 dt {
  font-weight: bold;
  border: none !important;
  width: 100% !important;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-bottom: 5px solid #E7ECFD !important;
  padding-bottom: 10px !important;
  font-size: 2rem;
}

.dl_consulting_01 dt span {
  color: #3654b2;
}

.dl_consulting_01 dd {
  border: none !important;
  width: 100% !important;
}

.dl_consulting_01 dl {
  border-radius: 10px;
  background-color: #fafafa;
  padding: 10px;
  display: block !important;
  width: 40%;
}

.dl_consulting_01 dl:first-of-type {
  position: absolute;
  left: 0;
  top: 0;
}

.dl_consulting_01 dl:nth-of-type(2) {
  position: absolute;
  right: 0;
  top: 0;
}

.dl_consulting_01 dl:nth-of-type(3) {
  position: absolute;
  left: 0;
  bottom: 0;
}

.dl_consulting_01 dl:last-of-type {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .dl_consulting_01 dl {
    position: inherit !important;
    width: 100%;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 834px) {
  .dl_consulting_01 dl {
    position: inherit !important;
    width: 100%;
    margin-bottom: 20px;
  }
}

.dl_consulting_02 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
}

@media screen and (max-width: 640px) {
  .dl_consulting_02 {
    grid-template-columns: repeat(1, 1fr);
  }
}

.dl_consulting_02 dt {
  font-weight: bold;
  border: none !important;
  width: 100% !important;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between !important;
  border-bottom: 5px solid #13a9b7 !important;
  padding-bottom: 10px !important;
  font-size: 2rem;
}

.dl_consulting_02 dt span {
  color: #3654b2;
}

.dl_consulting_02 dd {
  border: none !important;
  width: 100% !important;
}

.dl_consulting_02 dl {
  border-radius: 10px;
  background-color: #fafafa;
  padding: 10px;
  display: block !important;
}

.list_consulting_01 li {
  margin-bottom: 20px;
}

.list_consulting_01 li h5 {
  display: inline-block !important;
  font-weight: bold;
  background-color: #3654b2;
  padding: 8px;
  color: #fff;
  border-radius: 5px;
  line-height: 1em;
}

.list_consulting_02 {
  height: 100%;
}

.list_consulting_02 ul {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1fr);
  grid-template-rows: auto;
  gap: 10px;
}

.list_consulting_02 li {
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  padding: 10px;
  border-radius: 10px;
}

@media screen and (max-width: 834px) {
  .list_consulting_02 li {
    margin-bottom: 15px;
  }
}

.list_consulting_02 li:last-of-type {
  margin-bottom: 0;
}

.local_cont_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont_01 {
    display: block;
  }
}

.local_cont_01 .flxL {
  width: 48%;
}

.local_cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_01 .flxL img {
  border-radius: 10px;
}

.local_cont_01 .flex_03 .h_tel {
  margin-right: 0;
  margin-bottom: 15px;
}

.local_cont_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fafafa;
  padding: 30px;
}

@media screen and (max-width: 834px) {
  .local_cont_02 {
    display: block;
  }
}

.local_cont_02 .flxL {
  width: 48%;
}

.local_cont_02 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_02 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_02 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .local_cont_02 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_02 .flxL img {
  border-radius: 10px;
}

.local_cont_02 .flex_03 .h_tel {
  margin-right: 0;
  margin-bottom: 15px;
}

.local_cont_03 {
  position: relative;
}

.local_cont_03::before {
  position: absolute;
  top: 96px;
  left: 62px;
  width: 15px;
  height: 95%;
  content: '';
  background: #e7ecfd;
}

@media screen and (max-width: 834px) {
  .local_cont_03::before {
    display: none;
  }
}

.local_cont_03 .cont01, .local_cont_03 .cont02, .local_cont_03 .cont03 {
  margin-left: 10%;
}

@media screen and (max-width: 834px) {
  .local_cont_03 .cont01, .local_cont_03 .cont02, .local_cont_03 .cont03 {
    margin-left: 0;
  }
}

.local_flex_01 {
  justify-content: flex-start;
}

.local_flex_01 .box {
  width: calc((100% - 60px) / 3);
  text-align: center;
  margin-right: 30px;
  margin-bottom: 30px;
}

.local_flex_01 .box:nth-of-type(3n) {
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .local_flex_01 .box {
    width: 100%;
    margin-right: 0;
  }
}

.local_flex_01 .box img {
  border-radius: 10px;
}

.local_flex_02 {
  justify-content: space-between;
}

.local_flex_02 .box {
  width: calc((100% - 30px) / 2);
  text-align: center;
}

@media screen and (max-width: 640px) {
  .local_flex_02 .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .local_flex_02 .box:last-of-type {
    margin-bottom: 0;
  }
}

.local_flex_02 .box a {
  font-size: 1.25em;
  background-color: #13A9B7;
  padding: 20px;
  border-radius: 8px;
  display: block;
  color: #fff;
}

.local_flex_03 .box:last-of-type {
  margin-left: auto;
}

.local_flex_04 .box {
  margin-bottom: 30px;
}

.local_flex_04 .box article {
  display: flex;
  align-items: center;
}

.local_flex_04 .box article .title {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 1.25em;
  color: #3654b2;
}

.local_flex_04 .box article .title .deco {
  margin-right: 10px;
  color: #13a9b7;
  font-size: 24px;
}

.local_flex_04 .box:last-of-type {
  margin: 0;
}

.local_flex_05 .box {
  margin-bottom: 30px;
}

.local_flex_05 .box article {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  flex-wrap: wrap;
}

.local_flex_05 .box article img {
  width: 30% !important;
  border-radius: 10px;
  height: 200px !important;
  object-fit: cover;
}

@media screen and (max-width: 640px) {
  .local_flex_05 .box article img {
    width: 100% !important;
    height: auto !important;
  }
}

.local_flex_05 .box article > div {
  width: 60%;
}

@media screen and (max-width: 640px) {
  .local_flex_05 .box article > div {
    width: 100%;
  }
}

.local_flex_05 .box article .title {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  font-weight: bold;
  font-size: 1.25em;
  color: #3654b2;
}

.local_flex_05 .box article .title .deco {
  margin-right: 10px;
  color: #13a9b7;
  font-size: 24px;
}

.local_flex_05 .box:last-of-type {
  margin: 0;
}

.local_flex_06 {
  justify-content: space-between;
}

.local_flex_06 .box {
  border-radius: 10px;
  width: calc((100% - 30px) / 2);
  border: 1px solid #3654b2;
  text-align: center;
}

@media screen and (max-width: 640px) {
  .local_flex_06 .box {
    width: 100%;
    margin-bottom: 20px;
  }
  .local_flex_06 .box:last-of-type {
    margin-bottom: 0;
  }
}

.local_flex_06 .box article > div {
  padding: 15px;
}

.local_flex_06 .box h3 {
  background-color: #3654b2;
  color: #fff;
  font-weight: bold;
  padding: 15px;
}

.local_flex_07 {
  justify-content: space-between;
}

.local_flex_07 .box {
  width: calc((100% - 60px) / 4);
  text-align: center;
}

@media screen and (max-width: 640px) {
  .local_flex_07 .box {
    width: 100%;
    margin-bottom: 15px;
  }
  .local_flex_07 .box:last-of-type {
    margin-bottom: 0;
  }
}

.local_flex_07 .box a {
  font-size: 1.25em;
  background-color: #13A9B7;
  padding: 20px;
  border-radius: 8px;
  display: block;
  color: #fff;
}

.cont_tel div .h_tel {
  font-size: 2em;
  font-weight: 900;
}

.cont_tel div .h_tel i {
  margin-right: 10px;
}

.qa dd, .qa dt {
  border: none !important;
  width: 100% !important;
  line-height: 2.25em;
}

.qa dt {
  position: relative;
  padding: 20px 35px 20px 5.5rem !important;
  background-color: #e7ecfd !important;
}

.qa dt::before {
  content: "Q";
  font-size: 1.25em;
  color: #3654b2;
  font-weight: 900;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.qa dt::after {
  font-family: "Font Awesome 5 pro";
  content: "\f054";
  position: absolute;
  font-size: 14px;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.qa dl {
  display: block !important;
  margin-bottom: 20px;
}

.qa dl:last-of-type {
  margin-bottom: 0;
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeUpTrigger {
  opacity: 0;
}

.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dl_01 dt {
  border: none !important;
  font-weight: 500;
  color: #3654b2;
  display: block !important;
  width: 100% !important;
}

.dl_01 dd {
  border: none !important;
  display: block !important;
  width: 100% !important;
}

.dl_01 dl {
  display: block !important;
}

.dl_02 dt {
  border: none !important;
  color: #fff;
  border-radius: 8px;
  align-items: center !important;
  background-color: #3654b2 !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_02 dt {
    display: block !important;
    width: 100% !important;
  }
}

.dl_02 dd {
  border: none !important;
  padding: 15px !important;
}

@media screen and (max-width: 640px) {
  .dl_02 dd {
    display: block !important;
    width: 100% !important;
  }
}

.dl_02 dl {
  border-bottom: 1px dotted #ccc;
  padding: 10px 0;
}

@media screen and (max-width: 640px) {
  .dl_02 dl {
    display: block !important;
  }
}

.td_01 td div {
  text-align: center;
}

.td_01 .td_top {
  background-color: #3654b2;
  color: #fff;
}

.td_01 .td_L {
  background-color: #e7ecfd;
}

.list_01 {
  background-color: #e7ecfd;
  border-radius: 8px 0 0 8px;
  padding: 50px 30px;
}

.list_01 li {
  font-size: 2.2rem;
  color: #3654b2;
  border-bottom: 2px solid #fff;
  padding: 20px 20px 20px 5rem;
  position: relative;
  font-weight: 700;
  float: none !important;
}

.list_01 li::before {
  content: url(../images/home/check_icon.png);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #ccc !important;
  padding: 15px 0;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #3654b2;
  padding-bottom: 15px;
  font-weight: 500;
  margin: 50px 0 20px 0;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 200px;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.message {
  margin-top: 280px !important;
}
